import React from 'react';
import { Grid, Cell } from '@trbl/react-css-grid';
import ContentBlock from '../ContentBlock';
import ShapedImage from '../ShapedImage';
import CTACard from '../CTACard';
import Card from '../Card';

const BTFServices = React.memo(({ CTA, machines, blockImage, services }) => {
  return (
    <>
      <section>
        <Grid>
          <Cell
            hStart={5}
            hSpan={9}
            hStartM={5}
            hSpanM={11}
            hStartS={2}
            hSpanS={14}
          >
            <ContentBlock
              body="We're masters of many metal fabrication services. As the years go by, we've expanded our expertise to fulfill more client needs. Learn more about our processs by clicking the links below."
              headerAlign="center"
              bodyAlign="left"
            />
          </Cell>
        </Grid>
      </section>
      <section>
        <Grid
          id="services--services-list"
        >
          <Cell
            hStart={5}
            hSpan={10}
            hStartL={4}
            hSpanL={12}
            hStartM={3}
            hSpanM={12}
            hStartS={3}
            hSpanS={12}
          >
            <Grid>
              {services.nodes.map(({
                childMarkdownRemark: {
                  fields: { slug },
                  frontmatter: {
                    title,
                    photo,
                  },
                },
              }) => {
                return (
                  <Cell
                    hSpan={3}
                    hSpanL={4}
                    hSpanM={4}
                    hSpanS={12}
                  >
                    <Card
                      name={title}
                      slug={`/services${slug}`}
                      image={photo}
                    />
                  </Cell>
                )
              })}
            </Grid>
          </Cell>
        </Grid>
      </section>
      <section>
        <Grid
          id="services--featured"
        >
          <Cell
            hSpan={16}
            id="services--image-divider"
          >
            <ShapedImage
              color="green"
              backgroundImage={blockImage}
            />
          </Cell>
          <Cell
            hStart={2}
            hSpan={9}
            hStartL={1}
            hSpanL={10}
            hSpanM={14}
            hStartS={2}
            hSpanS={14}
            id="services--cta-card-container"
          >
            <CTACard
              title={CTA.title}
              description={CTA.description}
              image={CTA.photo}
              button={CTA.button}
            />
          </Cell>
        </Grid>
      </section>
      <aside>
        <Grid
          id="services--machines-list"
        >
          <Cell
            hStart={3}
            hSpan={12}
            hStartL={3}
            hSpanL={12}
            hStartM={2}
            hSpanM={6}
            hStartS={3}
            hSpanS={12}
            id="services--service-list-copy"
          >
            <h2>Our Machines</h2>
          </Cell>
          <Cell
            hStart={3}
            hSpan={12}
            hSpanL={12}
            hSpanM={12}
            hStartS={3}
            hSpanS={12}
          >
            <Grid>
              {machines.nodes.map(({
                childMarkdownRemark: {
                  fields: { slug },
                  frontmatter: {
                    title,
                    photo,
                  },
                },
              }) => {
                return (
                  <Cell
                    hSpan={4}
                    hSpanL={4}
                    hSpanM={4}
                    hSpanS={12}
                  >
                    <Card
                      name={title}
                      slug={`/machines${slug}`}
                      image={photo}
                    />
                  </Cell>
                )
              })}
            </Grid>
          </Cell>
        </Grid>
      </aside>
    </>
  );
});

export default BTFServices;
